const Image = ({
  src,
  alt,
  imagePosition,
}: {
  src: string;
  alt: string;
  imagePosition: string;
}) => {
  return (
    <div className={`Image-container ${imagePosition}`}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default Image;
