import useStore from "../../../../../../store/modalStore";
import "./index.css";

const LandingImage = () => {
  const { setIsOpen } = useStore();
  return (
    <div className="LandingImage-container">
      <img
        src={"/Hillview2.jpg"}
        alt="HillView"
        className="LandingImage-main"
      />
      <div className="LandingImage-content">
        <h1>Regenerating the World, One hectare at a time.</h1>
        {/* <p>
          Placeholder text Placeholder text Placeholder text Placeholder text
          Placeholder text Placeholder text
        </p>
        */}
        <button onClick={() => setIsOpen(true)}>Get Started</button>
      </div>
      <div className="LandingImage-content second"></div>
    </div>
  );
};

export default LandingImage;
