import { Route, Routes } from "react-router-dom";
import StarsLayer from "./components/layers/stars/stars-layer";
import AgricultureLayer from "./components/layers/agriculture/agriculture-layer";
import ButtonTree from "./features/button-tree/button-tree";
import Contact from "./features/contact-form/contact";
import HomePage from "./features/agriculture/homePage/homePage";
import AboutPage from "./features/agriculture/aboutPage/aboutPage";
import CaseStudiesPage from "./features/agriculture/caseStudiesPage/caseStudiesPage";
import PlatformPage from "./features/agriculture/platformPage/platformPage";

import "./App.css";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<StarsLayer />}>
        <Route index element={<ButtonTree />} />
        <Route path="contact/:token?" element={<Contact />} />
        <Route path=":token?" element={<ButtonTree />} />
        <Route path="*" element={<ButtonTree />} />
      </Route>
      <Route path="/agriculture" element={<AgricultureLayer />}>
        <Route index element={<HomePage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="case-studies" element={<CaseStudiesPage />} />
        <Route path="platform" element={<PlatformPage />} />
        <Route path="*" element={<HomePage />} />
      </Route>
    </Routes>
  );
};

export default App;
