import "./index.css";
import Item from "./item";
const About = () => {
  return (
    <div className="About-container">
      <div className="About-left">
        <h2>About Fresh Earth</h2>
        <p>
          Fresh Earth develops and manages regenerative agriculture projects in
          Australia, delivering practical, scientifically validated solutions.
          Our rigorous approach integrates advanced technology and compliance
          management to achieve tangible environmental and economic benefits for
          investors and the community.
        </p>
      </div>
      <div className="About-right">
        <Item
          icon={"/Time.svg"}
          iconAlt="Time"
          title="Project Evaluation"
          text="We conduct detailed land assessments to identify opportunities for soil carbon sequestration, biodiversity enhancement, and increased agricultural productivity."
        />
        <Item
          icon={"/Document.svg"}
          iconAlt="Document"
          title="Regenerative Project Design"
          text="Our specialists develop customised land management strategies aligned with Clean Energy Regulator (CER) methodologies and biodiversity stewardship frameworks."
        />
        <Item
          icon={"/Earth.svg"}
          iconAlt="Earth"
          title="Compliance & Governance"
          text="We manage project registration, monitoring, reporting, auditing, and carbon credit issuance in accordance with CER requirements and Australian regulatory standards."
        />
        <Item
          icon={"/Medal.svg"}
          iconAlt="Medal"
          title="Operational Management"
          text="We oversee every phase of project implementation and management to ensure compliance, mitigate risk, and deliver verified outcomes."
        />
      </div>
    </div>
  );
};

export default About;
