import "./index.css";

const LandImage = () => {
  return (
    <div className="LandImage-main">
      <img src={"/Land.png"} alt="Land" />
    </div>
  );
};
export default LandImage;
