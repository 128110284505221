import { useNavigate } from "react-router-dom";

const Content = ({
  title,
  text,
  buttonText,
  linkTo,
  imagePosition,
  hideButton,
}: {
  title: string;
  text: string;
  buttonText: string;
  linkTo: string;
  imagePosition: string;
  hideButton: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <div className={`Content-container ${imagePosition}`}>
      <div>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
      {!hideButton && (
        <button
          onClick={() => {
            navigate(linkTo);
          }}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default Content;
