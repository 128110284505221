import "./index.css";
import Case from "./components/Case/case";

const CaseStudies = () => {
  return (
    <div className="CaseStudies-main">
      <h1>Fresh Earth Case Studies</h1>
      <Case
        image={"/HillviewPark.png"}
        title="Hillview Park"
        alt="Hillview Park"
        land="Hillview Park"
        landImage={"/HillviewParkLand.png"}
        landText="Hillview Park is a historic mixed-grazing estate located near Goulburn in the Southern Tablelands of NSW. Covering 910.86 hectares (2251 acres), the property includes the notable Cobb & Co Inn, constructed by convicts around 1840. The estate features undulating terrain and low hills, diverse soil profiles, productive grazing pastures, and significant water resources. Currently, the property operates as an Angus cattle grazing enterprise and hosts a 13-turbine wind farm, a 191-hectare biodiversity stewardship site, and a 590-hectare soil carbon project."
        project="Hillview Park"
        projectImage={"/HillviewParkProject.png"}
        projectText={[
          "Soil Carbon Project (ERF189608): Currently in its first reporting stage, implementing improved rotational grazing and pasture enhancement practices to build soil organic carbon.",
          "Renewable Energy: A wind energy lease agreement with Crookwell Development and Union Fenosa for 13 turbines (46.8MW installed capacity). Additional renewable opportunities, including battery storage, a data centre, and solar generation, are being assessed.",
          "Biodiversity Stewardship Site: A 189-hectare native forest area assessed and approved by Biodiversity Stewardship Accredited ecologists.",
        ]}
      />
      <Case
        image={"/CooksvaleAggregation.png"}
        title="Cooksvale Aggregation"
        alt="Cooksvale Aggregation"
        land="Cooksvale Aggregation"
        landImage={"/CooksvaleAggregationLand.png"}
        landText="The Cooksvale Aggregation comprises three high-rainfall grazing properties totaling 760 hectares (1878 acres), located in the Southern Highlands–Tablelands region of NSW. The aggregation features productive creek flats, gently undulating grazing areas, and timbered hills. Approximately 55 kilometers from Crookwell and 167 kilometers from Canberra, it supports cattle production with additional opportunities for hay production, environmental plantings, forestry, soil carbon sequestration, and biodiversity conservation."
        project="Cooksvale Aggregation"
        projectImage={"/CooksvaleAggregationProject.png"}
        projectText={[
          "Cooksvale East Reforestation Project (ERF200842): Awaiting approval; currently preparing a 219-hectare area for mixed-species native environmental plantings.",
          "Cooksvale: Finalising applications for two Emissions Reduction Fund (ERF) projects covering 153 hectares, assessing suitability for either Reforestation or Soil Carbon methodologies. The soil carbon project focuses primarily on converting existing fodder crop and hay paddocks into permanent pasture.",
          "Cooksvale West: Undergoing discovery phase for a potential 126-hectare reforestation project.",
          "Biodiversity Conservation Site: Established with the Kanangra-Boyd to Wyangla (K2W) Link Partnership to monitor and support endangered species, including squirrel gliders, koalas, and platypus.",
        ]}
      />
      <Case
        image={"/Glenclair.png"}
        title="Glenclair"
        alt="Glenclair"
        land="Glenclair"
        landImage={"/GlenclairLand.png"}
        landText="The Glenclair Aggregation is a large-scale cattle grazing and breeding property located near Bendemeer in the Northern Tablelands of NSW. Spanning 4,209.88 hectares (10,403.45 acres) across three properties—Glenclair, Airlie Station, and Yaccamunda—the aggregation encompasses diverse landscapes including river flats, undulating slopes, and heavily timbered regions. Currently used for low-cost cattle grazing, it has significant potential for pasture improvement and infrastructure development. Glenclair is conveniently situated near regional hubs Tamworth and Armidale, offering access to essential services and amenities."
        project="Glenclair"
        projectImage={"/GlenclairProject.png"}
        projectText={[
          "ERF Projects: Planning is underway for two Emissions Reduction Fund (ERF) projects covering approximately 2,103 hectares, exploring both Reforestation and Soil Carbon methodologies. Project areas are being evaluated, with Reforestation currently the primary focus due to favourable FullCAM modelling results.",
          "Biodiversity Stewardship Site: Approximately 2,000 hectares of native forest targeted for assessment by Biodiversity Stewardship Accredited ecologists.",
        ]}
      />
    </div>
  );
};

export default CaseStudies;
