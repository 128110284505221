import { create } from "zustand";

type ModalStoreState = { isOpen: boolean };

type ModalStoreActions = {
  setIsOpen: (nextIsOpen: ModalStoreState["isOpen"]) => void;
};

type ModalStore = ModalStoreState & ModalStoreActions;
const useStore = create<ModalStore>()((set) => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
}));

export default useStore;
