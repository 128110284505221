const Item = ({
  icon,
  iconAlt,
  title,
  text,
}: {
  icon: string;
  iconAlt: string;
  title: string;
  text: string;
}) => {
  return (
    <div className="Item-container">
      <img src={icon} alt={iconAlt} />
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  );
};

export default Item;
