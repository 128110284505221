import ImageComponent from "./components/Image/imageComponent";
import ImpactAndVision from "./components/Impact/impact";
import Team from "./components/Team/team";
import TeamDescription from "./components/TeamDescription/teamDescription";
import "./index.css";

const About = () => {
  return (
    <div className="About-main">
      <ImageComponent />
      <ImpactAndVision />
      <Team />
      <TeamDescription />
    </div>
  );
};

export default About;
