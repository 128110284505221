import "./index.css";

const Organizations = () => {
  return (
    <div className="Organizations-main">
      <img src={"/nswbio.svg"} alt="NSW" />
      <img src={"/CER.svg"} alt="CER" />
      <img src={"/eco markets.svg"} alt="ECO" />
      <img src={"/goldstandard.svg"} alt="GoldStandard" />
      <img src={"/verra.svg"} alt="Verra" />
    </div>
  );
};
export default Organizations;
