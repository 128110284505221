import { Box, Typography } from "@mui/material";
import { Steps } from "../../../types/general";
import { useNavigate } from "react-router-dom";

type Props = {
  hidden: boolean;
  goToStepCallback: (step: Steps) => void;
};

export const AboutLayer = ({ goToStepCallback, hidden }: Props) => {
  const navigate = useNavigate();
  if (hidden) return <></>;
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: { xs: 10, md: 50 },
        left: "50%",
        transform: "translateX(-50%)",
        textAlign: "center",
        width: "100%",
        height: "auto",
        padding: { xs: 0, md: "8px 16px" },
        cursor: "pointer",
      }}
      onClick={() => {
        navigate("/agriculture");
      }}
    >
      <Typography
        sx={{
          color: "#FFBB2C",
          fontFamily: "Comfortaa",
          fontWeight: 600,
          fontSize: { xs: 14, md: 18 },
          lineHeight: "27px",
          textDecoration: "underline",
        }}
      >
        About Fresh Earth Agriculture
      </Typography>
    </Box>
  );
};
