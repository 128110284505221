import "./index.css";

const ProjectsImage = () => {
  return (
    <div className="ProjectsImage-main">
      <img src={"/Projects.png"} alt="Projects" />
    </div>
  );
};
export default ProjectsImage;
