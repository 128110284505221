import About from "./components/About/about";
import FourPillars from "./components/FourPillars/fourPillars";
import LandingImage from "./components/LandingImage/landingImage";
//import Sponsors from "./components/Sponsors/sponsors";
import Widget from "./components/Widget/widget";
import "./index.css";

const Home = () => {
  return (
    <div className="Home-main">
      <LandingImage />
      {/* <Sponsors /> */}
      <About />
      <FourPillars />
      {/* <Widget
        imageSrc={"/widget1.png"}
        imageAlt="Windmills"
        title="Land Assessment and Valuation:"
        buttonText="View Case Studies"
        linkTo="/agriculture/case-studies"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam nec tempor leo. Donec augue nunc, tempus sed leo ac, molestie faucibus dui. Etiam auctor id mauris vitae vestibulum. Curabitur sit amet interdum quam. Morbi interdum non libero et porttitor. Maecenas ipsum nisl, faucibus ut scelerisque vel, placerat quis lorem. Ut sagittis sagittis ipsum sit amet pellentesque. Ut hendrerit sollicitudin ex, pulvinar sagittis mi. Vivamus imperdiet sem et lorem malesuada lacinia. Nunc tempus lobortis venenatis. Nunc blandit nibh vel bibendum finibus. Nunc et laoreet nibh."
      /> */}
      <Widget
        imageSrc={"/widget2.png"}
        imageAlt="Forest"
        title="Regenerative Agriculture Project Development & Management"
        buttonText="View More"
        hideButton={true}
        linkTo="/agriculture"
        text="Fresh Earth partners with investors to design, develop, and manage regenerative agriculture projects. Our services include rigorous evaluation, tailored regenerative plans, compliance management, and professional operational oversight. Using advanced technology, we deliver measurable environmental and financial outcomes aligned with Australia’s carbon and biodiversity markets."
      />
      {/* <Widget
        imageSrc={"/widget3.png"}
        imageAlt="Landscape"
        title="Virtual collaboration and ownership"
        buttonText="View Our Platform"
        linkTo="/agriculture/platform"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam nec tempor leo. Donec augue nunc, tempus sed leo ac, molestie faucibus dui. Etiam auctor id mauris vitae vestibulum. Curabitur sit amet interdum quam. Morbi interdum non libero et porttitor. Maecenas ipsum nisl, faucibus ut scelerisque vel, placerat quis lorem. Ut sagittis sagittis ipsum sit amet pellentesque. Ut hendrerit sollicitudin ex, pulvinar sagittis mi. Vivamus imperdiet sem et lorem malesuada lacinia. Nunc tempus lobortis venenatis. Nunc blandit nibh vel bibendum finibus. Nunc et laoreet nibh."
      /> */}
    </div>
  );
};

export default Home;
