import "./index.css";
import ReusablePin from "./ReusablePin/reusablePin";

const Team = () => {
  return (
    <div className="Team-main">
      <h2>Meet Our Team:</h2>
      <div className="Team-grid">
        <ReusablePin
          color="#5c2483"
          imageUrl="/LachlanGraham.png"
          name="Lachlan Graham"
          title="Chief Farming Officer"
        />
        <ReusablePin
          color="#8B5E3C"
          imageUrl="/LukeMakepeace.jpeg"
          name="Luke Makepeace"
          title="Co-Founder Fresh Earth Universe"
        />
        <ReusablePin
          color="#1E6F30"
          imageUrl="/LukeStar.jpg"
          name="Luke Star"
          title="CFO Fresh Earth Ag"
        />
        <ReusablePin
          color="#FFC107"
          imageUrl="/KenChan.png"
          name="Ken Chan"
          title="Super Majors Fresh Earth Ag"
        />
        <ReusablePin
          color="#5c2483"
          imageUrl="/ShanaGavron.jpg"
          name="Shana Vida Gaveron"
          title="Biodiversity Advisor"
        />
        <ReusablePin
          color="#8B5E3C"
          imageUrl="/SimonSkillicorn.jpg"
          name="Simon Skillicorn"
          title="Strategic Partnerships Manager"
        />
        <ReusablePin
          color="#1E6F30"
          imageUrl="/DrPaulTaylor.png"
          name="Dr. Paul Taylor PhD"
          title="Co-Founder & Chief Scientist Head of Biochar"
        />
        <ReusablePin
          color="#FFC107"
          imageUrl="/RussellRankin.jpeg"
          name="Russell Rankin"
          title="Government Industry Expert"
        />
        <div></div>
        <ReusablePin
          color="#8B5E3C"
          imageUrl="/BenWills.jpg"
          name="Ben Willis"
          title="Legal"
        />
      </div>
    </div>
  );
};

export default Team;
