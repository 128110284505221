import Land from "./components/Land/land";
import LandImage from "./components/LandImage/landImage";
import Organizations from "./components/Organizations/organizations";
import Projects from "./components/Projects/projects";
import ProjectsImage from "./components/ProjectsImage/projectsImage";
import "./index.css";

const Platform = () => {
  return (
    <div className="Platform-main">
      <LandImage />
      <Land />
      <ProjectsImage />
      <Organizations />
      <Projects />
    </div>
  );
};

export default Platform;
