import { useState } from "react";
import Modal from "../../../components/modal/modal";
import "./index.css";
import useStore from "../../../store/modalStore";
import NotificationSnackbar from "../../../components/snackbar/snackbar";
import { createInquiryEmailBody } from "../../../utils/email-helper";
import { CircularProgress } from "@mui/material";

export type FormInformation = {
  name: string;
  email: string;
  company: string;
  phoneNumber: string;
  message: string;
};

const ContactForm = () => {
  const { isOpen, setIsOpen } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [showErrSnack, setShowErrSnack] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<FormInformation>({
    name: "",
    email: "",
    company: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_MC_URL}/api/sendsupportmail`;
    const recipient = process.env.REACT_APP_ENQUIRY_EMAIL_RECEIVER;

    setIsLoading(true);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipient,
        subject: `FreshEarth Agriculture Enquiry`,
        body: createInquiryEmailBody(formData),
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          setIsSubmitted(true);
          return;
        }

        setShowErrSnack(true);
      })
      .catch(() => setShowErrSnack(true))
      .finally(() => setIsLoading(false));
  };

  const resetForm = () => {
    setIsSubmitted(false);
    setFormData({
      name: "",
      email: "",
      company: "",
      phoneNumber: "",
      message: "",
    });
  };

  return (
    <>
      <NotificationSnackbar
        open={showErrSnack}
        message={"Something went wrong."}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        variant="error"
        onClose={() => setShowErrSnack(false)}
      />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          resetForm();
        }}
      >
        {isSubmitted ? (
          <div className="thank-you-message">
            <h2>Thank You!</h2>
            <p>Thanks for your enquiry. We’ll be in touch soon.</p>
          </div>
        ) : (
          <form className="contact-form-container" onSubmit={handleSubmit}>
            <h2>Sign Up</h2>
            <input
              type="text"
              name="name"
              required
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              required
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="text"
              name="company"
              required
              placeholder="Company"
              value={formData.company}
              onChange={handleChange}
            />
            <input
              type="text"
              name="phoneNumber"
              required
              placeholder="Phone number"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <textarea
              name="message"
              placeholder="Message"
              required
              rows={5}
              cols={40}
              value={formData.message}
              onChange={handleChange}
            />
            {isLoading ? (
              <CircularProgress sx={{ mr: 1, color: "#1e6f30" }} size={25} />
            ) : (
              <button type="submit">Submit</button>
            )}
          </form>
        )}
      </Modal>
    </>
  );
};

export default ContactForm;
