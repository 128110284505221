import "./index.css";

const DropdownImage = ({
  image,
  alt,
  text,
  onClick,
  isOpen,
}: {
  image: string;
  alt: string;
  text: string;
  onClick: () => void;
  isOpen: boolean;
}) => {
  return (
    <div className="DropdownImage-main">
      <img src={image} alt={alt} className="DropdownImage-cover" />
      <h2>{text}</h2>
      <div className="DropdownImage-arrow-container" onClick={onClick}>
        <img
          src={"/arrow.svg"}
          alt="Arrow"
          className={`DropdownImage-arrow ${isOpen ? "open" : ""}`}
        />
      </div>
    </div>
  );
};

export default DropdownImage;
