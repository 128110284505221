import "./index.css";

const Land = () => {
  return (
    <div className="Land-main">
      <h2>Land</h2>
      <p>
        At the farm evaluation stage, the platform offers farmers a
        comprehensive toolset to aggregate and analyze a wide range of farm
        data, empowering them to make informed management decisions. By
        utilizing advanced predictive models powered by deep learning, the
        platform helps farmers forecast future events like weather patterns,
        crop yields, pest outbreaks, and soil health fluctuations, allowing them
        to proactively plan and optimize their operations.
      </p>
      <p>
        Farmers can track key metrics in real time—soil quality, irrigation
        schedules, crop performance, and more—gaining valuable insights into the
        current state of their farm. With the platform’s intuitive dashboards,
        these data points are easily visualized, enabling farmers to quickly
        assess areas that need attention and prioritize tasks accordingly.
      </p>
      <p>
        Once the farm evaluation is complete, the platform creates a detailed,
        data-driven report that packages the farm’s current status, its
        regenerative potential, and the planned interventions for long-term
        sustainability. This report is then shared with investors, experts, and
        suppliers—each of whom can play a role in supporting the farm’s journey
        toward regenerative agriculture.
      </p>
      <p>
        Investors can use these reports to assess the farm’s environmental
        impact and growth potential, guiding them in making smart, sustainable
        investment decisions. Experts and consultants have access to the farm’s
        detailed metrics, allowing them to provide targeted advice and
        specialized support for regenerative practices. Meanwhile, suppliers of
        seeds, equipment, and other farm inputs can align their offerings with
        the farm’s needs, fostering a collaborative ecosystem that drives
        regenerative agriculture forward.
      </p>
      <p>
        By bringing together farmers, investors, experts, and suppliers in one
        platform, the system creates a seamless pathway for land transformation,
        helping farms thrive sustainably while contributing to broader
        environmental and economic goals.
      </p>
    </div>
  );
};
export default Land;
