import useWindowSize from "../../../../../../hooks/use-windows-size";
import Content from "./content";
import Image from "./image";
import "./index.css";

const Widget = ({
  imageSrc,
  imageAlt,
  title,
  text,
  buttonText,
  linkTo,
  hideButton = false,
  imagePosition = "left",
}: {
  imageSrc: string;
  imageAlt: string;
  title: string;
  text: string;
  buttonText: string;
  linkTo: string;
  hideButton?: boolean;
  imagePosition?: string;
}) => {
  const { width } = useWindowSize();
  return (
    <div className="Widget-container">
      {width > 768 && imagePosition === "left" && (
        <Image src={imageSrc} alt={imageAlt} imagePosition={imagePosition} />
      )}
      <Content
        title={title}
        text={text}
        buttonText={buttonText}
        imagePosition={imagePosition}
        linkTo={linkTo}
        hideButton={hideButton}
      />
      {width > 768 && imagePosition === "right" && (
        <Image src={imageSrc} alt={imageAlt} imagePosition={imagePosition} />
      )}
      {width < 768 && (
        <Image src={imageSrc} alt={imageAlt} imagePosition={imagePosition} />
      )}
    </div>
  );
};

export default Widget;
