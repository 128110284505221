import "./index.css";

const TeamDescription = () => {
  return (
    <div className="TeamDescription-main">
      <h2>Team Description</h2>
      <div className="TeamDescription-p">
        <p>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Samuel Johnston –
          Carbon Project and CER Compliance Lead
        </p>

        <p style={{ fontWeight: 500 }}>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Experienced in
          registering and delivering CER carbon projects, with in-house GIS
          mapping expertise to maintain project agency. Developed carbon credit
          forecast models with university researchers and mapped over 50
          potential farm projects across Australia, focusing on NSW.
          Successfully registered three CER-compliant projects under distinct
          methodologies and actively conducts R&D on regenerative livestock
          grazing systems.
        </p>
      </div>
      <div className="TeamDescription-p">
        <p>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Lachlan Graham –
          Chief Farming Officer
        </p>
        <p style={{ fontWeight: 500 }}>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Fourth-generation
          farmer from Harden, NSW, specializing in regenerative ruminant
          livestock systems. Founder and Co-CEO of a red meat company processing
          100+ tons of regenerative beef and lamb weekly, while leading
          decarbonisation projects across diverse assets. Brings decades of
          experience managing farms across NSW’s varied climates. Practicing
          regenerative agriculture since the early '90s, with carbon data dating
          back to 1994. Awarded "Australian Farmer of the Year" in 2013 and a
          2024 Banksia Sustainability Awards runner-up. Oversees projects in
          soil carbon, biodiversity stewardship, renewables, and energy storage.
        </p>
      </div>
      <div className="TeamDescription-p">
        <p>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Luke Star – Chief
          Financial Officer
        </p>
        <p style={{ fontWeight: 500 }}>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Expert in
          accounting, taxation, and finance, with a focus on strategic tax
          planning, risk management, and structuring through trusts and
          self-managed super funds. Works proactively across industries like
          hospitality, construction, healthcare, and property, ensuring
          up-to-date, adaptable knowledge. Proven success includes securing
          large insurance claims, guiding clients through bankruptcy to record
          profits, and optimizing company accounts for significant tax savings.
          Specializes in asset protection, cross-border tax compliance, and
          process improvement, with hands-on experience in ATO audits and
          internal controls.
        </p>
      </div>
      <div className="TeamDescription-p">
        <p>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Ken Chan - Super
          Majors
        </p>
        <p style={{ fontWeight: 500 }}>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Commercial gas and
          carbon consulting specialist with over 20 years experience working in
          energy supermajors and consulting to multinational Operators and
          Non-operating JVs on LNG/domgas/low-carbon project commercialisation
          and operationalization. Passionate about creating a level playing
          field that respects the difference, equality and interdependence of
          all people.
        </p>
      </div>
      <div className="TeamDescription-p">
        <p>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Ben Willis – Legal
        </p>
        <p style={{ fontWeight: 500 }}>
          <span style={{ fontWeight: "bold" }}>&#8226;</span> Experienced
          commercial lawyer with a background in top-tier private practice
          (Clayton Utz) and in-house roles at major companies like Optus,
          Foxtel, Sky News, and Seven Network. Former Group General Counsel &
          Company Secretary at NOVA Entertainment, serving on the national
          leadership team and as Privacy Officer. A graduate and member of the
          Australian Institute of Company Directors, with board and company
          secretary experience. Skilled in advising highly regulated industries,
          with a sharp ability to pinpoint critical issues and deliver
          practical, solution-oriented advice.
        </p>
      </div>
    </div>
  );
};

export default TeamDescription;
