import { useState } from "react";
import "./index.css";
import DropdownImage from "../DropdownImage/dropdownImage";
import Content from "../Content/content";

const Case = ({
  image,
  alt,
  title,
  land,
  landImage,
  landText,
  project,
  projectText,
  projectImage,
}: {
  image: string;
  alt: string;
  title: string;
  land: string;
  landImage: string;
  landText: string;
  project: string;
  projectText: string[];
  projectImage: string;
}) => {
  const [caseOpened, setCaseOpened] = useState(false);
  return (
    <div className="Case-main">
      <DropdownImage
        image={image}
        text={title}
        alt={alt}
        isOpen={caseOpened}
        onClick={() => setCaseOpened(!caseOpened)}
      />
      <div className={`Case-content ${caseOpened ? "open" : ""}`}>
        <Content
          land={land}
          landImage={landImage}
          landText={landText}
          project={project}
          projectImage={projectImage}
          projectText={projectText}
        />
      </div>
    </div>
  );
};

export default Case;
