import "./index.css";

const ReusablePin = ({
  imageUrl,
  color,
  name,
  title,
}: {
  imageUrl: string;
  color: string;
  name: string;
  title: string;
}) => {
  return (
    <div className="profile-pin">
      <div className="pin" style={{ backgroundColor: color }}>
        <div className="profile-image-container">
          <img src={imageUrl} alt={name} className="profile-image" />
        </div>
      </div>
      <h3 className="profile-name">{name}</h3>
      <p className="profile-title">{title}</p>
    </div>
  );
};

export default ReusablePin;
