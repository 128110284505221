import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

const StarsLayer = () => {
  const [height, setHeight] = useState<string>("100vh");

  const updateHeight = () => {
    setHeight(`${window.innerHeight}px`);
  };

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url('/starsCompressed.png')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        width: "100vw",
        height: height,
        overflow: "hidden",
      }}
    >
      <Outlet />
    </div>
  );
};

export default StarsLayer;
