import "./index.css";

const ImageComponent = () => {
  return (
    <div className="ImageComponent-main">
      <img src={"/prairie.png"} alt="Praire" />
      <h1>Fresh Earth</h1>
    </div>
  );
};

export default ImageComponent;
