import "./index.css";

const Projects = () => {
  return (
    <div className="Projects-main">
      <h2>Projects</h2>
      <p>
        In the Projects section of the platform, farmers can manage and execute
        regenerative agriculture projects from start to finish. The platform
        provides a detailed view of each project’s stages, allowing farmers to
        track progress, set milestones, and adjust their strategies based on
        real-time data. Farmers can use these tools to plan and implement
        regenerative practices such as soil restoration, water conservation,
        biodiversity enhancement, and carbon sequestration.
      </p>
      <p>
        A core feature of the platform is its robust management tools that help
        farmers monitor every aspect of their projects, from initial evaluation
        to ongoing execution. These tools include automated reporting features
        that streamline the creation of compliance reports for credits schemes
        like the ACCU (Australian Carbon Credit Units) Scheme and other relevant
        carbon offset and sustainability programs. By automatically generating
        reports that meet the rigorous standards of these schemes, the platform
        ensures that farmers remain compliant while also capturing the
        environmental value they create through regenerative practices.
      </p>
      <p>
        Once a project is ready for review or funding, the platform packages all
        data, including performance metrics, impact assessments, and credit
        certifications, into a comprehensive report. This makes it easy for
        farmers to present their projects to potential investors, experts, and
        suppliers who are interested in supporting regenerative agriculture.
      </p>
      <p>
        Investors gain access to detailed project data, enabling them to
        evaluate the financial and environmental impact of supporting the
        project. This allows them to make informed decisions about forward
        funding, overcoming the financing hurdles that many farmers face when
        transitioning to regenerative practices. By offering forward funding,
        investors can help farms implement crucial projects without the burden
        of upfront costs, supporting long-term sustainability while earning
        credits or returns on their investment.
      </p>
      <p>
        Experts, including agronomists, environmental consultants, and carbon
        specialists, can use the platform to review project plans, offer
        technical guidance, and ensure the projects are designed and implemented
        effectively. Suppliers, such as those providing sustainable inputs or
        equipment, can align their offerings with the project’s specific needs,
        further contributing to the farm’s regenerative journey.
      </p>
      <p>
        Overall, the platform not only helps farmers successfully execute
        regenerative projects but also fosters collaboration between all
        stakeholders—investors, experts, and suppliers—creating a seamless
        ecosystem that accelerates the transition to regenerative agriculture.
      </p>
    </div>
  );
};
export default Projects;
