import "./index.css";

const Content = ({
  landText,
  land,
  landImage,
  projectText,
  project,
  projectImage,
}: {
  landText: string;
  land: string;
  landImage: string;
  projectText: string[];
  project: string;
  projectImage: string;
}) => {
  return (
    <div className="Content-main">
      <h2>{land} - Land</h2>
      <img src={landImage} alt={land} />
      <p>{landText}</p>
      <h2>{project} - Project</h2>
      <img src={projectImage} alt={project} />
      {projectText.map((text, index) => (
        <p key={index}>
          <span>&#8226;</span>
          {text}
        </p>
      ))}
    </div>
  );
};

export default Content;
