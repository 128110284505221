import "./index.css";

const ImpactAndVision = () => {
  return (
    <div className="ImpactAndVision-main">
      <h2>Impact And Vision:</h2>
      <p>
        Our vision is to accelerate Australia’s ecological regeneration through
        scalable, measurable, and scientifically validated regenerative
        agriculture projects. We prioritise restoring soil health, enhancing
        biodiversity, increasing agricultural resilience, and providing
        investors measurable outcomes aligned with carbon and biodiversity
        markets.
      </p>

      <a href="mailto:luke.s@freshearth.io">
        <button>Contact Us</button>
      </a>
    </div>
  );
};

export default ImpactAndVision;
