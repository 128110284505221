import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import "./index.css";
import useWindowSize from "../../hooks/use-windows-size";
import useStore from "../../store/modalStore";

const conditions = ["about", "case-studies", "platform"];

const Navbar = () => {
  const { setIsOpen: setIsModalOpen } = useStore();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { width } = useWindowSize();
  const isHomePage =
    location.pathname.includes("/agriculture") &&
    !conditions.some((el) => location.pathname.includes(el));

  useEffect(() => {
    setIsScrolled(window.scrollY > 10);
    if (!isHomePage) return;

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isHomePage]);

  return (
    <nav
      className={`navbar ${
        isHomePage ? (isScrolled ? "solid" : "transparent") : "solid"
      }`}
    >
      <div className="nav-content">
        <div className="logo">
          <img src={"/FreshEarthLogo.svg"} alt="Fresh Earth Logo" />
        </div>

        {width <= 768 && (
          <button
            className="sign-up"
            style={{ marginLeft: 0 }}
            onClick={() => setIsModalOpen(true)}
          >
            Sign Up
          </button>
        )}
        {/* Mobile Menu Button */}
        <button className="menu-toggle" onClick={() => setIsOpen(!isOpen)}>
          ☰
        </button>

        <div
          className={`nav-links-container ${isOpen ? "open" : ""} ${
            isHomePage ? (isScrolled ? "solid" : "transparent") : "solid"
          }`}
        >
          <ul className="nav-links">
            <li>
              <NavLink
                to="/agriculture"
                className={
                  location.pathname === "/agriculture" ? "active" : "inactive"
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/agriculture/about"
                className={
                  location.pathname === "/agriculture/about"
                    ? "active"
                    : "inactive"
                }
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/agriculture/case-studies"
                className={
                  location.pathname === "/agriculture/case-studies"
                    ? "active"
                    : "inactive"
                }
              >
                Case Studies
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/agriculture/platform"
                className={
                  location.pathname === "/agriculture/platform"
                    ? "active"
                    : "inactive"
                }
              >
                Platform
              </NavLink>
            </li>
          </ul>
          {width > 768 && (
            <button className="sign-up" onClick={() => setIsModalOpen(true)}>
              Sign Up
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
