import { Outlet } from "react-router-dom";
import Navbar from "../../navbar/navbar";
import ScrollToTop from "../../scrollToTop/scrollToTop";
import ContactForm from "../../../features/agriculture/contactForm/contactForm";

const AgricultureLayer = () => {
  return (
    <>
      <ContactForm />
      <ScrollToTop />
      <Navbar />
      <Outlet />
    </>
  );
};

export default AgricultureLayer;
