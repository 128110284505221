import "./index.css";

const FourPillars = () => {
  return (
    <div className="FourPillars-container">
      <div className="FourPillars-left">
        <h2>Four Pillars of Agriculture:</h2>
        <div className="FourPillars-left-mobile">
          <div>
            <h3>1. Land Evaluation</h3>
            <p>
              Our team conducts rigorous land assessments, combining advanced
              scientific methods and accurate data modeling to precisely
              identify the best regenerative agriculture opportunities, ensuring
              maximum environmental and financial value for investors and
              landholders.
            </p>
          </div>
          <div>
            <h3>2. Project Development</h3>
            <p>
              We streamline regenerative project delivery by coordinating
              agricultural specialists, trusted suppliers, farm operators, and
              investors. Our structured planning approach ensures projects are
              designed efficiently, meet compliance standards, and generate
              profitable, measurable results.
            </p>
          </div>
          <div>
            <h3>3. Farm Operations</h3>
            <p>
              We provide structured, best-practice operational oversight,
              offering ongoing support and real-time monitoring. Our rigorous
              approach ensures farm activities remain compliant, efficient, and
              aligned with project objectives, delivering consistent, verified
              outcomes for stakeholders.
            </p>
          </div>
          <div>
            <h3>4. Funding Solutions</h3>
            <p>
              We offer diversified funding solutions, facilitating access to
              premium environmental markets, carbon credits, and biodiversity
              stewardship programs. Our tailored finance models help investors
              and landowners efficiently capitalize regenerative agriculture
              projects, maximizing long-term returns and sustainability.
            </p>
          </div>
        </div>
      </div>
      <div className="FourPillars-right">
        <img src={"/four-pillars.svg"} alt="Four Pillars of Agriculture" />
      </div>
    </div>
  );
};

export default FourPillars;
